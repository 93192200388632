import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back Squat 8-8-8\\@70% 1RM`}</p>
    <p>{`Glute Ham Raise 8-8-8`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds of:`}</p>
    <p>{`20-Step Ups holding KB to chest, alternating legs (24/20″, 53/35`}{`#`}{`)`}</p>
    <p>{`20-KBS’s`}</p>
    <p>{`20-Goblet Squats`}</p>
    <p>{`cash out: 50 Burpees`}</p>
    <p>{`for total time.`}</p>
    <p><strong parentName="p">{`*`}{`Saturday, January 13th is Cardinal Fitness Day at the Yum!.  The
Cards play Virginia Tech at 4:00pm.  With the following link you can get
discounted tickets as a CrossFit the Ville member!  After the game there
will be a CrossFit wod on the court along with several other workout
sessions.  Lets get a big group together and represent The Ville at the
Yum center!  Family is invited as well.`}</strong></p>
    <p><strong parentName="p"><a parentName="strong" {...{
          "href": "https://fevo.me/fitday"
        }}>{`https://fevo.me/fitday`}</a></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program will start January 7th.  Email Eric for
more info: Fallscitystrength\\@gmail.com.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      